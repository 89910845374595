<template>
  <v-app :style="{ background: $vuetify.theme.themes.light.background }">
    <v-layout row>
      <v-flex>
        <v-app :style="{ background: $vuetify.theme.themes.dark.background }" class="">
          <v-container fluid>
            <v-row class="pt-5 pb-5" style="width: 100%">
              <v-col cols="12" sm="10">
                <v-card style="padding: 1rem">
                  <v-row>
                    <v-col cols="12" sm="3">
                      <v-dialog
                        ref="dialog"
                        v-model="modal"
                        :return-value.sync="fechaInicio"
                        persistent
                        width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="fechaInicio"
                            label="Fecha De Inicio"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          @change="cambiarFecha"
                          locale="es"
                          v-model="fechaInicio"
                          scrollable
                        >
                          <v-spacer></v-spacer>
                          <v-btn text color="primary" @click="modal = false">
                            Cancel
                          </v-btn>
                          <v-btn
                            text
                            color="primary"
                            @click="$refs.dialog.save(fechaInicio)"
                          >
                            OK
                          </v-btn>
                        </v-date-picker>
                      </v-dialog>
                    </v-col>
                    <v-col cols="12" sm="3">
                      <!-- <h1>Seleccione el tipo de entrega</h1> -->
                      <v-dialog
                        ref="dialog2"
                        v-model="modal2"
                        :return-value.sync="fechaFin"
                        persistent
                        width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="fechaFin"
                            label="Fecha De Fin"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker locale="es" v-model="fechaFin" scrollable>
                          <v-spacer></v-spacer>
                          <v-btn text color="primary" @click="modal2 = false">
                            Cancel
                          </v-btn>
                          <v-btn
                            text
                            color="primary"
                            @click="$refs.dialog2.save(fechaFin)"
                          >
                            OK
                          </v-btn>
                        </v-date-picker>
                      </v-dialog>
                    </v-col>
                    <v-col cols="12" sm="3">
                      <v-autocomplete
                        v-model="tipoContrato"
                        :items="tiposContrato"
                        dense
                        label="Seleccione el tipo de contrato"
                        style="margin-top: 1.4rem"
                        item-text="valor"
                        item-value="clave"
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="3">
                      <v-autocomplete
                        v-model="ciclo"
                        :items="ciclos"
                        dense
                        label="Seleccione el Ciclo de Facturación"
                        style="margin-top: 1.4rem"
                        item-text="valor"
                        item-value="clave"
                      ></v-autocomplete>
                    </v-col>
                  </v-row>
                  <div class="d-flex justify-center">
                    <v-btn @click="buscar" rounded color="primary" dark> Buscar </v-btn>
                  </div>
                  <template>
                    <v-data-table
                      :headers="headersInternos2"
                      :items="datos"
                      item-key="ID_Desparasitacion"
                      class="elevation-1"
                      :search="search"
                      ref="datatable"
                    >
                      <template v-slot:top>
                        <v-toolbar flat>
                          <v-toolbar-title>
                            <v-btn
                              @click="excel"
                              height="100%"
                              dark
                              color="green darken-1"
                            >
                              <v-icon class="mb-2" large> fas fa-file-excel </v-icon>
                            </v-btn>
                            <v-dialog transition="dialog-bottom-transition">
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                 
                                  height="100%"
                                  dark
                                  style="margin-left:1rem;"
                                  
                                   v-bind="attrs"
                                   color="green darken-1"
                                   @click="mostrar"
                                  v-on="on"
                                >
                                  Mostrar Facturas
                                </v-btn>
                                
                              </template>
                              <template v-slot:default="dialog">
                                <v-card>
                                  <simple-datatable
                                    :headers="headers"
                                    :datos="data2"
                                  >
                                  </simple-datatable>
                                  <v-card-actions class="justify-end">
                                    <v-btn
                                      color="red accent-4"
                                      style="color: white"
                                      @click="dialog.value = false"
                                      >Cerrar</v-btn
                                    >
                                  </v-card-actions>
                                </v-card>
                              </template>
                            </v-dialog>
                          </v-toolbar-title>
                          <v-spacer></v-spacer>
                          <!-- <v-switch
                            v-model="singleExpand"
                            label="Single expand"
                            class="mt-2"
                          ></v-switch> -->
                          <v-text-field
                            v-model="search"
                            append-icon="mdi-magnify"
                            label="Buscar"
                            single-line
                            hide-details
                          ></v-text-field>
                        </v-toolbar>
                      </template>

                      <!-- <template v-slot:expanded-item="{ headers, item }">
                          <td :colspan="headers.length" style="padding: 0">
                            <v-card style="width: 100vw">
                              <v-card-text>
                                <v-btn
                                  @click="registrar(item)"
                                  height="100%"
                                  dark
                                  color="green darken-1"
                                >
                                  Registrar Entrega
                                </v-btn>
                              </v-card-text>
                            </v-card>
                          </td>
                        </template> -->
                    </v-data-table>
                  </template>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-app>
      </v-flex>
    </v-layout>
  </v-app>
</template>

<script>
import axios from "axios";
export default {
  data: () => ({
    fechaInicio: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    fechaFin: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    modal: false,
    modal2: false,
    headers: [
      {
        text: "Nombre Grupo",
        value: "grunom",
      },
      {
        text: "Numero Beneficiarios Facturados",
        value: "NoBen",
      },
      {
        text: "Numero Beneficiarios activos",
        value: "cuantos",
      },
      {
        text: "Radicado",
        value: "grugru",
      },
      
      
    ],
    headersInternos: [
      {
        text: "Número Factura",
        value: "NoFactura",
      },
      {
        text: "Tipo Contrato",
        value: "Tipo_Cont",
      },
      {
        text: "Nuevo Saldo",
        value: "Nvo_saldo",
      },
      {
        text: "Saldo Anterior ",
        value: "Saldo_Ant",
      },
      {
        text: "Valor Total",
        value: "Val_Tot",
      },
      {
        text: "Radicado",
        value: "grugru",
      },
      {
        text: "Nombre Factura",
        value: "Nombre_Fac",
      },
      {
        text: "Ciclo",
        value: "Ciclo",
      },
      {
        text: "Corte",
        value: "Corte",
      },
      {
        text: "Fecha Factura",
        value: "FechaFac",
      },
    ],
    headersInternos2: [
      {
        text: "Numero Factura",
        value: "NoFactura",
      },
      {
        text: "Fecha Factura",
        value: "FechaFac",
      },
      {
        text: "Numero Beneficiarios Facturados",
        value: "NoBen",
      },
      {
        text: "Numero Beneficiarios Activos",
        value: "cuantos",
      },
      {
        text: "Iva",
        value: "IVAFac",
      },
      {
        text: "Valor Total",
        value: "Val_Tot",
      },
      {
        text: "Nuevo Saldo",
        value: "Nvo_saldo",
      },
      {
        text: "Saldo Anterior",
        value: "Saldo_Ant",
      },
      {
        text: "Fecha Vencimiento",
        value: "CARFVN",
      },
      {
        text: "dias vencidos",
        value: "dias",
      },

      {
        text: "Radicado",
        value: "grugru",
      },
    ],
    data2: [],
    datos: [],
    search: "",

    tipoContrato: -1,
    tiposContrato: [],
    ciclo: -1,
    ciclos: [],
    cicloTemp: null,
    fechaInicialTemp: null,
    fechaFinalTemp: null,
    tipoContratoTemp: null,
    cuantosTemp: null,
    cuantos: -1,
    cuantosLista: [
      { clave: -1, valor: "TODOS" },
      { clave: 0, valor: 0 },
      { clave: 1, valor: 1 },
      { clave: -2, valor: "Mas de 1" },
      { clave: 2, valor: 2 },
      { clave: 3, valor: 3 },
      { clave: 4, valor: 4 },
    ],
    data4: [],
  }),
  created() {
    this.$store.state.stateLoad = true;
    axios
      .get("/cobranza/tipocontratos", {
        headers: {
          // Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        this.tiposContrato.push({
          clave: -1,
          valor: "TODOS",
        });
        response.data.forEach((element) => {
          this.tiposContrato.push({
            clave: element.clave,
            valor: element.valor,
          });
        });
      });
    axios
      .get("/cobranza/ciclos", {
        headers: {
          // Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        this.ciclos.push({
          clave: -1,
          valor: "TODOS",
        });
        response.data.forEach((element) => {
          this.ciclos.push({
            clave: element.clave - 1,
            valor: element.valor,
          });
        });
        this.$store.state.stateLoad = false;
      });
  },
  computed: {
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
  },
  methods: {
    buscar() {
      this.$store.state.stateLoad = true;
      axios
        .get("/facturacion/revisionfactura", {
          params: {
            fechaInicial: this.fechaInicio,
            fechaFinal: this.fechaFin,
            ciclo: this.ciclo,
            tipoContrato: this.tipoContrato,
            cuantos: this.cuantos,
          },

          headers: {
            // Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((response) => {
          this.datos = [];
          this.datos = response.data[0];
          this.cicloTemp = this.ciclo;
          this.fechaInicialTemp = this.fechaInicio;
          this.fechaFinalTemp = this.fechaFin;
          this.tipoContratoTemp = this.tipoContrato;
          this.cuantosTemp = this.cuantos;
          this.$store.state.stateLoad = false;
          response.data[0].forEach(element => {
            ()=>{

            }
          });
        });
    },
    mostrar() {
      this.$store.state.stateLoad = true;
      axios
        .get("/facturacion/mostrarfacturas", {
          params: {
            fechaInicial: this.fechaInicio,
            fechaFinal: this.fechaFin,
            ciclo: this.ciclo,
            tipoContrato: this.tipoContrato,
            cuantos: this.cuantos,
          },

          headers: {
            // Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((response) => {
          console.log(response);
          this.data2 = response.data[0]
          this.$store.state.stateLoad = false;
        });
    },
    async dataPorGruGru(GruGru) {
      this.data2 = [];
      this.$store.state.stateLoad = true;
      await axios
        .get("/cobranza/carteravencida/dataPorGruGru", {
          params: {
            grugru: GruGru,
            ciclo: this.cicloTemp,
            fechaInicial: this.fechaInicialTemp,
            fechaFinal: this.fechaFinalTemp,
            tipoContrato: this.tipoContratoTemp,
            cuantos: this.cuantosTemp,
          },

          headers: {
            // Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((response) => {
          console.log(response.data);
          this.data2 = response.data[0];
          this.$store.state.stateLoad = false;
        });
    },
    async dataPorGruGru2(GruGru) {
      this.data2 = [];
      this.$store.state.stateLoad = true;
      await axios
        .get("/cobranza/carteravencida/dataPorGruGru2", {
          params: {
            grugru: GruGru,
            ciclo: this.cicloTemp,
            fechaInicial: this.fechaInicialTemp,
            fechaFinal: this.fechaFinalTemp,
            tipoContrato: this.tipoContratoTemp,
            cuantos: this.cuantosTemp,
          },

          headers: {
            // Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((response) => {
          console.log(response.data);
          this.data2 = response.data[0];
          this.$store.state.stateLoad = false;
        });
    },
    cambiarFecha() {
      if (this.fechaInicio > this.fechaFin) {
        this.fechaFin = new Date(this.fechaInicio).toISOString().substr(0, 10);
      }
    },
    excel() {
      axios({
        method: "post",
        url: "/excel",
        data: {
          datos: this.datos,
        },

        headers: {
          // Authorization: `Bearer ${localStorage.getItem("token")}`,
        },

        responseType: "blob",
      }).then((response) => {
        // $("#boxLoading").removeClass("loading");
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "file.xlsx");
        document.body.appendChild(link);
        link.click();
        // console.log(response);
      });
    },
    async facturas(grugru) {
      this.data4 = [];
      this.$store.state.stateLoad = true;

      await axios
        .get("/cobranza/carteravencida/facturas", {
          params: {
            grugru: grugru,
          },

          headers: {
            // Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((response) => {
          console.log(response.data);
          this.data4 = response.data[0];
          this.$store.state.stateLoad = false;
        });
    },
  },
};
</script>
<style scoped>
h1 {
  font-size: larger !important;
}
.buscar {
  width: 200px;
  border-radius: 30px !important;
  background: #2196f3;
  margin-top: 10px;
  height: 2.2rem;
  color: white;
}
</style>
