import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Login from "../views/Login.vue";

import inicio from "../subviews/Home";
import FullCalendarVista from "../subviews/FullCalendarVista";

import CicloActivo from "../subviews/CicloActivo";
import PDF from "../subviews/PDF";
import registrar from "../subviews/Register.vue";
import HistoricoDesparasitacion from "../subviews/HistoricoDesparasitacion";
import Prueba from "../subviews/Prueba";
import IndicadoresDesempeno from "../subviews/IndicadoresDesempeno";
import RegistrarEntregaDesparasitacion from "../subviews/RegistrarEntregaDesparasitacion";
import GestionarCarteraVencida from '../subviews/GestionarCarteraVencida'
import RevisionFactura from '../subviews/RevisionFactura'
import InformeDian from '../subviews/InformeDian'
import RevisionAnexos from '../subviews/RevisionAnexos'
import GestionarCarteraVencida2 from '../subviews/GestionarCarteraVencida2'
import Agregar from '../subviews/Agregar'
import TiempoPromedioConsulta from '../subviews/TiempoPromedioConsulta'
import ExportarContables from '../subviews/ExportarContables'
import ExportarContablesDocument from '../subviews/ExportarContablesDocument'

import HistoriasClinicasMascotas from '../subviews/HistoriasClinicasMascotas'
import store from "../store";



Vue.use(VueRouter);
Vue.filter("numbers", function (value) {
  value = value.toString().replace(/[^0-9]+/g, "");
  while (/(\d+)(\d{3})/.test(value.toString())) {
    value = value.toString().replace(/(\d+)(\d{3})/, "$1" + "," + "$2");
  }
  return value;
});
const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    children: [
      {
        path: "/home",
        name: "inicio",
        component: inicio
      },
      {
        path: "/calendar",
        name: "calendar",
        component: FullCalendarVista,
        beforeEnter: (to, from, next) => {
          if(store.state.credentials['Agenda Personal']){
            Axios.defaults.headers.common[
              "Authorization"
            ] = `Bearer ${store.state.token}`;
            next()
          }else{
            next({name: '404'})
          }
        }
      },
      {
        path: "/pdf",
        name: "pdfservicioveterinario",
        component: PDF,
        beforeEnter: (to, from, next) => {
          if(store.state.credentials['Imprimir Etiquetas Desparasitacion']){
            Axios.defaults.headers.common[
              "Authorization"
            ] = `Bearer ${store.state.token}`;
            next()
          }else{
            next({name: '404'})
          }
        }
      },
      {
        path: "/cicloactivo",
        name: "nuevocicloactivo",
        component: CicloActivo,
        beforeEnter: (to, from, next) => {
          if(store.state.credentials['Cambiar Ciclo Activo']){
            Axios.defaults.headers.common[
              "Authorization"
            ] = `Bearer ${store.state.token}`;
            next()
          }else{
            next({name: '404'})
          }
        }
      },
      {
        path: "/registrar",
        name: "registrar",
        component: registrar,
        beforeEnter: (to, from, next) => {
          if(store.state.credentials['Registrar Desparasitacion']){
            Axios.defaults.headers.common[
              "Authorization"
            ] = `Bearer ${store.state.token}`;
            next()
          }else{
            next({name: '404'})
          }
        }
      },
      {
        path: "/historicodesparasitacion",
        name: "historicodesparasitacion",
        component: HistoricoDesparasitacion,
        beforeEnter: (to, from, next) => {
          if(store.state.credentials['Ver Historico Desparasitacion']){
            Axios.defaults.headers.common[
              "Authorization"
            ] = `Bearer ${store.state.token}`;
            next()
          }else{
            next({name: '404'})
          }
        }
      },
      
      {
        path: "/entregar",
        name: "registrarentregadesparasitacion",
        component: RegistrarEntregaDesparasitacion,
        beforeEnter: (to, from, next) => {
          if(store.state.credentials['Registrar Entrega Desparasitacion Cobrador']){
            Axios.defaults.headers.common[
              "Authorization"
            ] = `Bearer ${store.state.token}`;
            next()
          }else{
            next({name: '404'})
          }
        }
      },
      
     /*  {
        path: "/agregar",
        name: "agregar",
        component: Agregar,
      },RUTA ADMIN */
      /* {
        path: "/prueba",
        name: "prueba",
        component: Prueba,
      }, RUTA MITCHEL*/
      {
        path: "/exportarcontables",
        name: "exportarcontables",
        component: ExportarContables,
        beforeEnter: (to, from, next) => {
          if(store.state.credentials['Exportar Contables Facturador']){
            Axios.defaults.headers.common[
              "Authorization"
            ] = `Bearer ${store.state.token}`;
            next()
          }else{
            next({name: '404'})
          }
        }
      },
      {
        path: "/exportarcontablesdocument",
        name: "exportarcontablesdocument",
        component: ExportarContablesDocument,
        beforeEnter: (to, from, next) => {
          if(store.state.credentials['Exportar Contables Facturador']){
            Axios.defaults.headers.common[
              "Authorization"
            ] = `Bearer ${store.state.token}`;
            next()
          }else{
            next({name: '404'})
          }
        }
      },
      {
        path: "/historiasclinicasmascotas",
        name: "historiasclinicasmascotas",
        component: HistoriasClinicasMascotas,
        beforeEnter: (to, from, next) => {
          if(store.state.credentials['Descargar Historias Clinicas']){
            Axios.defaults.headers.common[
              "Authorization"
            ] = `Bearer ${store.state.token}`;
            next()
          }else{
            next({name: '404'})
          }
        }
      },
    ],
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: '*',
    name: "404",
    component: () => import(/* webpackChunkName: "about" */ '../views/Page404.vue')
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
