<template>
  <v-app :style="{ background: $vuetify.theme.themes.light.background }">
    <v-layout row>
      <v-flex>
        <v-app :style="{ background: $vuetify.theme.themes.dark.background }" class="">
          <v-container fluid>
            <v-row class="pt-5 pb-5" style="width: 100%">
              <v-col cols="10" sm="10">
                <v-card style="padding: 1rem">
                  <v-row>
                    <v-col cols="12" sm="4">
                      <v-dialog
                        ref="dialog"
                        v-model="modal"
                        :return-value.sync="fechaInicio"
                        persistent
                        width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="fechaInicio"
                            label="Fecha De Inicio"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          @change="cambiarFecha"
                          locale="es"
                          v-model="fechaInicio"
                          scrollable
                        >
                          <v-spacer></v-spacer>
                          <v-btn text color="primary" @click="modal = false">
                            Cancel
                          </v-btn>
                          <v-btn
                            text
                            color="primary"
                            @click="$refs.dialog.save(fechaInicio)"
                          >
                            OK
                          </v-btn>
                        </v-date-picker>
                      </v-dialog>
                    </v-col>
                    <v-col cols="12" sm="4">
                      <!-- <h1>Seleccione el tipo de entrega</h1> -->
                      <v-dialog
                        ref="dialog2"
                        v-model="modal2"
                        :return-value.sync="fechaFin"
                        persistent
                        width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="fechaFin"
                            label="Fecha De Fin"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker locale="es" v-model="fechaFin" scrollable>
                          <v-spacer></v-spacer>
                          <v-btn text color="primary" @click="modal2 = false">
                            Cancel
                          </v-btn>
                          <v-btn
                            text
                            color="primary"
                            @click="$refs.dialog2.save(fechaFin)"
                          >
                            OK
                          </v-btn>
                        </v-date-picker>
                      </v-dialog>
                    </v-col>
                    <v-col cols="12" sm="4">
                      <v-autocomplete
                        v-model="triajeSelected"
                        :items="triaje"
                        dense
                        label="Seleccione el triaje"
                        style="margin-top: 1.4rem"
                        item-text="valor"
                        item-value="clave"
                      ></v-autocomplete>
                    </v-col>
                    
                  </v-row>
                  <div class="d-flex justify-center">
                    <v-btn @click="buscar" rounded color="primary" dark> Buscar </v-btn>
                  </div>
                 
                </v-card>
                <h1>TIEMPO PROMEDIO: {{tiempoPromedio}}</h1>
              </v-col>
            </v-row>
          </v-container>
        </v-app>
      </v-flex>
    </v-layout>
  </v-app>
</template>

<script>
import axios from "axios";
export default {
  data: () => ({
    fechaInicio: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    fechaFin: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    modal: false,
    modal2: false,
    valor: 0,
    tiempoPromedio: null,

    triajeSelected: 'todos',
    triaje: [
        {
            clave: 'todos',
            valor: 'todos'
        },
        {
            clave: 'v',
            valor: 'verde'
        },
        {
            clave: 'a',
            valor: 'amarillo'
        },
        {
            clave: 'r',
            valor: 'rojo'
        },
        {
            clave: 'p',
            valor: 'azul'
        },
    ],
  }),
  created() {
    
  },
  computed: {
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
  },
  methods: {
    buscar() {
      this.$store.state.stateLoad = true;
      this.valor = 0;
      this.totalFacturado = 0;

      axios
        .get("/temp", {
          params: {
            fechaInicial: this.fechaInicio,
            fechaFinal: this.fechaFin,
            triaje: this.triajeSelected
          },

          headers: {
            // Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((response) => {
        this.$store.state.stateLoad = false;
          this.tiempoPromedio = response.data[0][0].total_time
          

        });
    },
    cambiarFecha() {
      if (this.fechaInicio > this.fechaFin) {
        this.fechaFin = new Date(this.fechaInicio).toISOString().substr(0, 10);
      }
    },
  },
};
</script>
<style scoped>
h1 {
  font-size: larger !important;
}
.buscar {
  width: 200px;
  border-radius: 30px !important;
  background: #2196f3;
  margin-top: 10px;
  height: 2.2rem;
  color: white;
}
</style>
