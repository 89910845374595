<template>
  <v-app>
    

    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",

  data: () => ({
    //
  }),
  beforeCreate(){
    // if(!localStorage.getItem('token')){
    //   this.$router.push({
    //     name: 'Login'
    //   })
    // }
  },
  mounted() {
    
  },
  methods: {
    
  },
};
</script>
