<template>
  <v-app :style="{ background: $vuetify.theme.themes.light.background }">
    <v-layout row>
      <v-flex>
        <v-app :style="{ background: $vuetify.theme.themes.dark.background }" class="">
          <v-container fluid>
            <v-row class="pt-5 pb-5" style="width: 100%">
              <v-col cols="12" sm="12">
                <v-card style="padding: 1rem">
                  
                  <template>
                    <v-data-table
                      :headers="headers"
                      :items="data"
                      :items-per-page="5"
                      class="elevation-1"
                    ></v-data-table>
                  </template>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-app>
      </v-flex>
    </v-layout>
  </v-app>
</template>

<script>
import axios from 'axios'
export default {
  data: () => ({
    
    items: ["Rojo", "Verde", "Amarillo"],
    headers: [
      {
        text: "config",
        value: "config"
      },
      { text: "CSC", value: "reqcsc" },
      { text: "Valh", value: "reqreg" },
      { text: "Fecha", value: "reqdia" },
      { text: "Hora llamada", value: "reqhor" },
      { text: "movil", value: "reqveh" },
      { text: "Triage", value: "reqcda" },
      { text: "Prioridad", value: "prioridades_calculadas" },
      { text: "Barrio/ Comuna/ Zona", value: "WebMapingBarrioCSC" },
      { text: "Programacion movil", value: "isPrgEdit" },
      { text: "Tiempo salida consulta", value: "Tiempo salida consulta" },
      { text: "Hora Despacho", value: "reqhdp" },
      { text: "Sale", value: "reqhsb" },
      { text: "llega", value: "reqhll" },
      { text: "Sale", value: "reqhsd" },
      { text: "Llgada institucion", value: "reqhyi" },
      { text: "Sale institucion", value: "reqhsi" },
      { text: "Disponible", value: "reqhdi" },
      { text: "Cancela", value: "reqhcc" },
      { text: "Rellamada", value: "rellamadas" },
    ],
    data: []
  }),
  created(){
    this.$store.state.stateLoad = true;
    axios
      .get("/servicios-activos", {
        headers: {
          // Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        console.log(response.data);
        this.data = response.data;
        this.$store.state.stateLoad = false;
      });
  }
};
</script>
<style>
.select .v-input__control {
  overflow: hidden;
}
</style>
