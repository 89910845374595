<template>
  <v-app :style="{ background: $vuetify.theme.themes.light.background }">
    <v-layout row>
      <v-flex>
        <v-app :style="{ background: $vuetify.theme.themes.dark.background }" class="">
          <v-container fluid>
            <v-row class="pt-5 pb-5" style="width: 100%">
              <v-col cols="12" sm="12">
                <v-card style="padding: 1rem">
                  <h1>Historico Desparasitación</h1>
                  <h1>El Ciclo Actual Es: {{ cicloActual }}</h1>
                  <v-row>
                    <v-col cols="12" sm="4">
                      <!-- <h1>Seleccione el ciclo a consultar</h1> -->
                      <v-autocomplete
                        v-model="ciclo"
                        :items="ciclosMostrar"
                        dense
                        filled
                        :label="`Seleccione el ${labelCiclo} a consultar`"
                        item-text="clave"
                        item-value="valor"
                      ></v-autocomplete>
                      <v-switch
                        style="margin: 1rem"
                        v-model="switch1"
                        @click="switchLabel"
                        :label="`Consultando: ${labelCiclo}`"
                      ></v-switch>
                    </v-col>
                    <v-col cols="12" sm="4">
                      <!-- <h1>Seleccione el estado de entrega</h1> -->
                      <v-autocomplete
                        v-model="estadoEntrega"
                        :items="arrayEntrega"
                        dense
                        filled
                        label="Seleccione el estado de entrega"
                        item-text="clave"
                        item-value="valor"
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="4">
                      <!-- <h1>Seleccione el tipo de entrega</h1> -->
                      <v-autocomplete
                        v-model="tipoEntrega"
                        :items="arrayTipoEntrega"
                        dense
                        filled
                        label="Seleccione el tipo de entrega"
                        item-text="clave"
                        item-value="valor"
                      ></v-autocomplete>
                    </v-col>
                    <div class="d-flex justify-center" style="margin-bottom: 1rem;">
                      <v-btn
                        @click="buscar(), (buscando = true)"
                        :loading="buscando"
                        rounded
                        color="primary"
                        dark
                      >
                        Buscar
                      </v-btn>
                    </div>
                  </v-row>
                  <template v-if="!buscando">
                    <v-data-table
                      :headers="headers"
                      :items="datos"
                      item-key="ID_Desparasitacion"
                      class="elevation-1"
                      :search="search"
                      ref="datatable"
                    >
                      <template v-slot:top>
                        <v-toolbar flat>
                          <v-toolbar-title>
                            <v-btn
                              @click="excel"
                              height="100%"
                              dark
                              color="green darken-1"
                            >
                              <v-icon class="mb-2" large> fas fa-file-excel </v-icon>
                            </v-btn>
                          </v-toolbar-title>
                          <v-spacer></v-spacer>
                          <!-- <v-switch
                            v-model="singleExpand"
                            label="Single expand"
                            class="mt-2"
                          ></v-switch> -->
                          <v-text-field
                            v-model="search"
                            append-icon="mdi-magnify"
                            label="Buscar"
                            single-line
                            hide-details
                          ></v-text-field>
                        </v-toolbar>
                      </template>
                      <!-- <template v-slot:expanded-item="{ headers, item }">
                          <td :colspan="headers.length" style="padding: 0">
                            <v-card style="width: 100vw">
                              <v-card-text>
                                <v-btn
                                  @click="registrar(item)"
                                  height="100%"
                                  dark
                                  color="green darken-1"
                                >
                                  Registrar Entrega
                                </v-btn>
                              </v-card-text>
                            </v-card>
                          </td>
                        </template> -->
                    </v-data-table>
                  </template>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-app>
      </v-flex>
    </v-layout>
  </v-app>
</template>

<script>
import axios from 'axios'
export default {
  data: () => ({
    items: ["foo", "bar", "fizz", "buzz"],
    values: ["foo", "bar"],
    value: null,
    arrayEntrega: [],
    ciclos: [],
    arrayTipoEntrega: [],
    periodos: [],
    ciclosMostrar: [],
    ciclo: -1,
    estadoEntrega: -1,
    tipoEntrega: -1,
    buscando: false,
    labelCiclo: "ciclo",
    cicloActual: null,
    search: "",
    switch1: true,
    headers: [
      { text: "ID Desparasitación", value: "ID_Desparasitacion" },
      { text: "ID Usuario", value: "ID_Usuario" },
      { text: "ID Titular", value: "ID_Titular" },
      { text: "ID Grupo", value: "ID_Grupo" },
      { text: "Ciclo Desparasitación", value: "Ciclo_Desparasitacion" },

      { text: "Fecha Registro", value: "Fecha_Registro" },
      { text: "Fecha Proxima Desparasitación", value: "Fecha_Proxima_Desparasitacion" },
      { text: "SMS", value: "SMS" },
      { text: "Estado Entrega", value: "Estado_Entrega" },
      { text: "Tipo De Entrega", value: "Tipo_De_Entrega" },
    ],
    datos: [],
  }),
  created() {
    this.$store.state.stateLoad = true;
    axios
      .get("/servicio_medico/ciclos", {
        headers: {
          // Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        this.ciclos.push({ clave: "Todos", valor: -1 });
        this.ciclos.push({ clave: "Ciclo 0", valor: 0 });
        response.data.forEach((element) => {
          this.ciclos.push({
            clave: "Ciclo " + element.desparasitacion_ciclo,
            valor: element.desparasitacion_ciclo,
          });
        });
        this.ciclosMostrar = this.ciclos;
        
      });
    axios
      .get("/servicio_medico/tipoEntrega", {
        headers: {
          // Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        this.arrayTipoEntrega.push({
          clave: "Todos",
          valor: -1,
        });
        response.data.forEach((element) => {
          this.arrayTipoEntrega.push({
            clave: element.clave,
            valor: element.valor,
          });
        });
        
      });
    axios
      .get("/servicio_medico/estadoEntrega", {
        headers: {
          // Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        this.arrayEntrega.push({
          clave: "Todos",
          valor: -1,
        });
        response.data.forEach((element) => {
          this.arrayEntrega.push({
            clave: element.clave,
            valor: element.valor,
          });
        });
      });
    axios
      .get("/servicio_medico/pdfCicloActivo", {
        headers: {
          // Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        this.periodos.push({ clave: "Todos", valor: -1 });
        response.data.forEach((element) => {
          this.periodos.push({
            valor: element.clave,
            clave: element.valor,
          });
        });
      });
    axios
      .get("/servicio_medico/cicloActual", {
        headers: {
          // Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        this.cicloActual = response.data[0].cliclo_activo;
        this.$store.state.stateLoad = false;
      });
  },
  computed: {
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
  },
  methods: {
    buscar() {
      this.$store.state.stateLoad = true;
      axios
        .get(
          "/servicio_medico/datosHistorico",
          {
            params: {
              ciclo: this.ciclo,
              estadoEntrega: this.estadoEntrega,
              tipoEntrega: this.tipoEntrega,
              diferenciador: this.switch1 ? 1 : 0,
            },
            headers: {
              // Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          },
          
        )
        .then((response) => {
          this.datos = [];
          response.data.forEach((element) => {
            this.datos.push({
              ID_Desparasitacion: element.ID_Desparasitacion,
              ID_Usuario: element.ID_Usuario,
              ID_Titular: element.ID_Titular,
              ID_Grupo: element.ID_Grupo,
              Ciclo_Desparasitacion: element.Ciclo_Desparasitacion,
              Fecha_Registro: element.Fecha_Registro,
              Fecha_Proxima_Desparasitacion: element.Fecha_Proxima_Desparasitacion,
              SMS: element.SMS,
              Estado_Entrega: element.Estado_Entrega,
              Tipo_De_Entrega: element.Tipo_De_Entrega,
            });
          });
          this.buscando = false;
          this.$store.state.stateLoad = false;
        });
    },
    excel() {
      this.$store.state.stateLoad = true;
      axios({
        method: "post",
        url: "/excel",
        data: {
          datos: this.datos,
        },

        headers: {
          // Authorization: `Bearer ${localStorage.getItem("token")}`,
        },

        responseType: "blob",
      }).then((response) => {
        this.$store.state.stateLoad = false;
        // $("#boxLoading").removeClass("loading");
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "file.xlsx");
        document.body.appendChild(link);
        link.click();
        // console.log(response);
      });
    },
    switchLabel() {
      console.log(this.switch1);
      if (this.switch1) {
        this.labelCiclo = "ciclo";
        this.ciclosMostrar = this.ciclos;
        this.ciclo = -1;
      } else {
        this.labelCiclo = "periodo";
        this.ciclosMostrar = this.periodos;
        this.ciclo = -1;
      }
    },
  },
};
</script>
<style scoped>
h1 {
  font-size: larger !important;
}
.buscar {
  width: 200px;
  border-radius: 30px !important;
  background: #2196f3;
  margin-top: 10px;
  height: 2.2rem;
  color: white;
}
</style>
