<template>
  <v-app :style="{ background: $vuetify.theme.themes.light.background }">
    <v-layout row>
      <v-flex>
        <v-app :style="{ background: $vuetify.theme.themes.dark.background }" class="">
          <v-container fluid>
            <v-row class="pt-5 pb-5" style="width: 100%">
              <v-col cols="12" sm="12">
                <v-card style="padding: 1rem">
                  <v-row>
                    <v-col cols="12" sm="4" style="padding-top: 0.5rem">
                      <!-- <h1>Seleccione el ciclo a consultar</h1> -->
                      <v-autocomplete
                        v-model="informe"
                        :items="informes"
                        dense
                        background-color="white"
                        filled
                        height="10"
                        :label="`Seleccionar Informe`"
                        item-text="clave"
                        item-value="valor"
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="4">
                      <v-dialog
                        ref="dialog"
                        v-model="modal"
                        :return-value.sync="fechaInicio"
                        persistent
                        width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="fechaInicio"
                            label="Fecha De Inicio"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          @change="cambiarFecha"
                          locale="es"
                          v-model="fechaInicio"
                          scrollable
                        >
                          <v-spacer></v-spacer>
                          <v-btn text color="primary" @click="modal = false">
                            Cancel
                          </v-btn>
                          <v-btn
                            text
                            color="primary"
                            @click="$refs.dialog.save(fechaInicio)"
                          >
                            OK
                          </v-btn>
                        </v-date-picker>
                      </v-dialog>
                    </v-col>
                    <v-col cols="12" sm="4">
                      <!-- <h1>Seleccione el tipo de entrega</h1> -->
                      <v-dialog
                        ref="dialog2"
                        v-model="modal2"
                        :return-value.sync="fechaFin"
                        persistent
                        width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="fechaFin"
                            label="Fecha De Fin"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker locale="es" v-model="fechaFin" scrollable>
                          <v-spacer></v-spacer>
                          <v-btn text color="primary" @click="modal2 = false">
                            Cancel
                          </v-btn>
                          <v-btn
                            text
                            color="primary"
                            @click="$refs.dialog2.save(fechaFin)"
                          >
                            OK
                          </v-btn>
                        </v-date-picker>
                      </v-dialog>
                    </v-col>
                    <div class="d-flex justify-center">
                      <v-btn
                        :disabled="!informe"
                        @click="exportar"
                        rounded
                        color="primary"
                        dark
                      >
                        Exportar
                      </v-btn>
                      <v-dialog v-model="dialog" width="600px" v-if="informe == 1">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn rounded color="primary" dark v-bind="attrs" v-on="on">
                            Cambiar Corte
                          </v-btn>
                        </template>
                        <v-card>
                          <v-card-title>
                            <span class="text-h5">Cambiar el corte</span>
                          </v-card-title>
                          <v-card-text>
                            <v-text-field
                              v-model="corte"
                              hide-details
                              single-line
                              type="number"
                            />
                          </v-card-text>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              color="green darken-1"
                              text
                              @click="(dialog = false), (corte = null)"
                            >
                              Cerrar
                            </v-btn>
                            <v-btn
                              color="green darken-1"
                              text
                              @click="
                                cambiarCorte(corte), (dialog = false), (corte = null)
                              "
                            >
                              Guardar
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </div>
                  </v-row>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-app>
      </v-flex>
    </v-layout>
  </v-app>
</template>

<script>
import axios from "axios";
export default {
  data: () => ({
    informes: [
      {
        clave: "Exportar Facturas POS",
        valor: 1,
      },
      {
        clave: "Exportar Datos Terceros Facturas POS Yeminus",
        valor: 2,
      },
    ],
    informe: null,
    fechaInicio: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    fechaFin: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    modal: false,
    modal2: false,
    corte: null,
    dialog: false,
  }),
  created() {
    this.$store.state.stateLoad = true;

    this.$store.state.stateLoad = false;
  },
  computed: {
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
  },
  methods: {
    cambiarFecha() {
      if (this.fechaInicio > this.fechaFin) {
        this.fechaFin = new Date(this.fechaInicio).toISOString().substr(0, 10);
      }
    },
    exportar() {
      this.$store.state.stateLoad = true;
      switch (this.informe) {
        case 1:
          axios
            .get("/contabilidad/exportarfacturaspos", {
              params: {
                fechaInicial: this.fechaInicio,
                fechaFinal: this.fechaFin,
              },
              headers: {
                // Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            })
            .then((response) => {
              console.log(response);
              var Datos = response.data;
              var complemento = Datos.complemento;
              Datos = Datos.data;
              console.log(complemento, Datos);
              var datosExcel = [];

              //Ahora lo que hacemos para este algoritmo es tomar la longitud total de los datos que nos devuelven para empezar a restar cosas
              //Debemos tener en cuenta que por la naturaleza de la consulta muchos datos van a cas que repetidos pero son necesarios para el calculo
              //La idea es restarle a la longitud la cantidad de datos que hemos consumido
              //Esto se podría hacer parecer a cuando usamos un for y avanzamos en el orden del resultado
              //Primero 0 después 1 y después 2
              //Aqui sería algo diferente teniendo primer 0, luego 3, luego 7 por ejemplo
              //Que tanto se le reste a la longitud esta determinado por cuantas veces se repite el dato
              //Ahora otra cosa para tener mas claridad, tenemos una variable llamada indice
              //Esta variable hace las veces como el apuntador a nuestros datos
              //Entonces para mas claridad:
              //Tenemos una longitud base que va a ir disminuyendo
              //Mientras que el indice va a ir aumentando
              //De manera en que cuando la longitud llegue a cero, el indice debe de haber recorrido toda la estructura
              if (Datos != null && Datos.length > 0) {
                var prueba = [];
                var factura = Datos[0].number;
                var prefijo = complemento[0].documento_contable;
                var prefix = complemento[0].prefix;
                var concepto = complemento[0].concepto;
                var consecutivo_lote_pos = complemento[0].consecutivo_lote_pos;
                var anulado = [];
                var debito2 = 0;
                var debitoAux = 0;
                for (let index = 0; index < Datos.length; index++) {
                  anulado.push(Datos[index].state_type_id == 11);

                  var fecha = Datos[index].date_of_issue;
                  var consecutivoFactura = Datos[index].number;
                  var cedula = Datos[index].numeroPersona;
                  var nombre = Datos[index].name;
                  var cuenta = Datos[index]["Cuenta Contable"];
                  var descripcion =
                    prefix +
                    " " +
                    consecutivoFactura +
                    " " +
                    (anulado[index] == true ? "ANULADO" : Datos[index].descripcion);

                  var centroPago = Datos[index].centro_costo;
                  var debito = "";
                  var credito = anulado[index] == true ? 0 : Datos[index].credito;
                  var descuentoItem = Datos[index].DescuentoItem;
                  //DESCRIPCION SE SACA DE LA TABLA contabilidad_puc
                  //La idea con debito2 es sacar un subtotal calculado mediante debitos y creditos
                  if (Datos[index].porcentaje == 100) {
                    datosExcel.push({
                      " ": prefijo,
                      "  ": "-",
                      "   ": consecutivo_lote_pos,
                      "Fecha Factura": fecha,
                      "Consecutivo Factura": consecutivoFactura,
                      "Tercero Correspondiente": cedula,
                      "Cuenta Contable": cuenta,
                      "Nombre Completo": nombre,
                      "Descripcion Factura": descripcion,
                      Concepto: concepto,
                      "Centro De Costo": centroPago,
                      Debito: debito,
                      Credito: credito,
                      Alterado: "",
                      "Debito 2": "",
                      "Descuento Item": descuentoItem,
                      "Descuento Total": "",
                    });

                    if (consecutivoFactura != factura) {
                      debitoAux += parseFloat(credito);
                    } else {
                      debito2 += parseFloat(credito);
                    }
                  }

                  if (consecutivoFactura != factura) {
                    var pagos =
                      Datos[index - 1].asd == null
                        ? [" "]
                        : Datos[index - 1].asd.split(",");
                    var cuentas =
                      Datos[index - 1].bsd == null
                        ? [" "]
                        : Datos[index - 1].bsd.split(",");
                    var terceros =
                      Datos[index - 1].csd == null
                        ? [" "]
                        : Datos[index - 1].csd.split(",");
                    var descripcionPago =
                      Datos[index - 1].dsd == null
                        ? [" "]
                        : Datos[index - 1].dsd.split(",");

                    const res = Array.from(
                      prueba.reduce(
                        (m, { tax, value }) => m.set(tax, (m.get(tax) || 0) + value),
                        new Map()
                      ),
                      ([tax, value]) => ({ tax, value })
                    );

                    res.forEach((element) => {
                      if (element.value > 0) {
                        /* for (var indiceInterno = 0; indiceInterno < prueba.length; indiceInterno++) {
                                if(prueba[indiceInterno].tax == element.tax){}
                                
                            } */

                        var contador = 0;
                        var descripcionInterna = "";
                        while (descripcionInterna == "") {
                          if (prueba[contador].tax == element.tax) {
                            descripcionInterna = prueba[contador].descripcion;
                          } else {
                            contador++;
                            if (contador > prueba.length) {
                              descripcionInterna = " ";
                            }
                          }
                        }
                        datosExcel.push({
                          " ": prefijo,
                          "  ": "-",
                          "   ": consecutivo_lote_pos,
                          "Fecha Factura": Datos[index - 1].date_of_issue,
                          "Consecutivo Factura": Datos[index - 1].number,
                          "Tercero Correspondiente": Datos[index - 1].numeroPersona,
                          "Cuenta Contable": element.tax,
                          "Nombre Completo": Datos[index - 1].name,
                          "Descripcion Factura":
                            prefix +
                            " " +
                            (consecutivoFactura - 1) +
                            " " +
                            (anulado[index - 1] == true ? "ANULADO" : descripcionInterna),
                          Concepto: concepto,
                          "Centro De Costo": centroPago,
                          Debito: "",
                          Credito: anulado[index - 1] == true ? 0 : element.value,
                          Alterado: "",
                          "Debito 2": "",
                          "Descuento Item": "",
                          "Descuento Total": "",
                        });

                        debito2 += parseFloat(
                          anulado[index - 1] == true ? 0 : element.value
                        );
                      }
                    });

                    prueba = [];
                    if (Datos[index].porcentaje != 100) {
                      if (Datos[index].tax_id != 8) {
                        prueba.push({
                          tax: Datos[index]["Cuenta Contable"],
                          value: parseFloat(Datos[index].total_tax),
                          consecutivo: consecutivoFactura,
                          descripcion: Datos[index].descripcion,
                        });
                      }
                    }
                    for (let indice = 0; indice < pagos.length; indice++) {
                      datosExcel.push({
                        " ": prefijo,
                        "  ": "-",
                        "   ": consecutivo_lote_pos,
                        "Fecha Factura": Datos[index - 1].date_of_issue,
                        "Consecutivo Factura": Datos[index - 1].number,
                        "Tercero Correspondiente":
                          terceros[indice] == null ? "" : terceros[indice],
                        "Cuenta Contable": cuentas[indice] == null ? "" : cuentas[indice],
                        "Nombre Completo": Datos[index - 1].name,
                        "Descripcion Factura":
                          prefix +
                          " " +
                          (consecutivoFactura - 1) +
                          " " +
                          (anulado[index - 1] == true
                            ? "ANULADO"
                            : descripcionPago[indice] == null
                            ? ""
                            : descripcionPago[indice]),
                        Concepto: concepto,
                        "Centro De Costo": centroPago,
                        Debito:
                          anulado[index - 1] == true
                            ? 0
                            : pagos[indice] == null
                            ? ""
                            : pagos[indice] == 0
                            ? Datos[index - 1].total
                            : pagos[indice],
                        Credito: "",
                        Alterado: pagos[indice] == 0 ? "ALTERADO" : "",
                        "Debito 2": debito2,
                        "Descuento Item": "",
                        "Descuento Total": Datos[index - 1].DescuentoTotal,
                      });
                    }
                    debito2 = debitoAux;
                    debitoAux = 0;

                    factura = consecutivoFactura;
                  } else {
                    if (Datos[index].porcentaje != 100) {
                      if (Datos[index].tax_id != 8) {
                        prueba.push({
                          tax: Datos[index]["Cuenta Contable"],
                          value: parseFloat(Datos[index].total_tax),
                          consecutivo: consecutivoFactura,
                          descripcion: Datos[index].descripcion,
                        });
                      }
                    }
                  }
                  if(index == (Datos.length - 1)){
                    var pagos =
                      Datos[index].asd == null
                        ? [" "]
                        : Datos[index].asd.split(",");
                    var cuentas =
                      Datos[index].bsd == null
                        ? [" "]
                        : Datos[index].bsd.split(",");
                    var terceros =
                      Datos[index].csd == null
                        ? [" "]
                        : Datos[index].csd.split(",");
                    var descripcionPago =
                      Datos[index].dsd == null
                        ? [" "]
                        : Datos[index].dsd.split(",");

                    const res = Array.from(
                      prueba.reduce(
                        (m, { tax, value }) => m.set(tax, (m.get(tax) || 0) + value),
                        new Map()
                      ),
                      ([tax, value]) => ({ tax, value })
                    );

                    res.forEach((element) => {
                      if (element.value > 0) {
                        /* for (var indiceInterno = 0; indiceInterno < prueba.length; indiceInterno++) {
                                if(prueba[indiceInterno].tax == element.tax){}
                                
                            } */

                        var contador = 0;
                        var descripcionInterna = "";
                        while (descripcionInterna == "") {
                          if (prueba[contador].tax == element.tax) {
                            descripcionInterna = prueba[contador].descripcion;
                          } else {
                            contador++;
                            if (contador > prueba.length) {
                              descripcionInterna = " ";
                            }
                          }
                        }
                        datosExcel.push({
                          " ": prefijo,
                          "  ": "-",
                          "   ": consecutivo_lote_pos,
                          "Fecha Factura": Datos[index].date_of_issue,
                          "Consecutivo Factura": Datos[index].number,
                          "Tercero Correspondiente": Datos[index].numeroPersona,
                          "Cuenta Contable": element.tax,
                          "Nombre Completo": Datos[index].name,
                          "Descripcion Factura":
                            prefix +
                            " " +
                            (consecutivoFactura) +
                            " " +
                            (anulado[index] == true ? "ANULADO" : descripcionInterna),
                          Concepto: concepto,
                          "Centro De Costo": centroPago,
                          Debito: "",
                          Credito: anulado[index] == true ? 0 : element.value,
                          Alterado: "",
                          "Debito 2": "",
                          "Descuento Item": "",
                          "Descuento Total": "",
                        });

                        debito2 += parseFloat(
                          anulado[index] == true ? 0 : element.value
                        );
                      }
                    });

                    prueba = [];
                    if (Datos[index].porcentaje != 100) {
                      if (Datos[index].tax_id != 8) {
                        prueba.push({
                          tax: Datos[index]["Cuenta Contable"],
                          value: parseFloat(Datos[index].total_tax),
                          consecutivo: consecutivoFactura,
                          descripcion: Datos[index].descripcion,
                        });
                      }
                    }
                    for (let indice = 0; indice < pagos.length; indice++) {
                      datosExcel.push({
                        " ": prefijo,
                        "  ": "-",
                        "   ": consecutivo_lote_pos,
                        "Fecha Factura": Datos[index].date_of_issue,
                        "Consecutivo Factura": Datos[index].number,
                        "Tercero Correspondiente":
                          terceros[indice] == null ? "" : terceros[indice],
                        "Cuenta Contable": cuentas[indice] == null ? "" : cuentas[indice],
                        "Nombre Completo": Datos[index - 1].name,
                        "Descripcion Factura":
                          prefix +
                          " " +
                          (consecutivoFactura) +
                          " " +
                          (anulado[index] == true
                            ? "ANULADO"
                            : descripcionPago[indice] == null
                            ? ""
                            : descripcionPago[indice]),
                        Concepto: concepto,
                        "Centro De Costo": centroPago,
                        Debito:
                          anulado[index] == true
                            ? 0
                            : pagos[indice] == null
                            ? ""
                            : pagos[indice] == 0
                            ? Datos[index - 1].total
                            : pagos[indice],
                        Credito: "",
                        Alterado: pagos[indice] == 0 ? "ALTERADO" : "",
                        "Debito 2": debito2,
                        "Descuento Item": "",
                        "Descuento Total": Datos[index].DescuentoTotal,
                      });
                    }
                    debito2 = debitoAux;
                    debitoAux = 0;

                    factura = consecutivoFactura;
                  }
                }
                datosExcel.sort(
                  (a, b) =>
                    parseFloat(a["Consecutivo Factura"]) -
                    parseFloat(b["Consecutivo Factura"])
                );
                axios({
                  method: "post",
                  url: "/excel",
                  data: {
                    datos: datosExcel,
                  },
                  headers: {
                    // Authorization: `Bearer ${localStorage.getItem("token")}`,
                  },
                  responseType: "blob",
                }).then((response) => {
                  this.$store.state.stateLoad = false;
                  // $("#boxLoading").removeClass("loading");
                  const url = window.URL.createObjectURL(new Blob([response.data]));
                  const link = document.createElement("a");
                  link.href = url;
                  link.setAttribute("download", "file.xlsx");
                  document.body.appendChild(link);
                  link.click();

                  // console.log(response);
                });
              }else{
                  this.$store.state.stateLoad = false;

              }
              
              
            });
          break;

        case 2:
          this.$store.state.stateLoad = true;
          axios
            .post(
              "/contabilidad/exportardatostercerospos",
              {
                fecha_ini: this.fechaInicio,
                fecha_fin: this.fechaFin,
              },
              {
                headers: {
                  // Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
              }
            )
            .then((response) => {
              
              axios({
                method: "post",
                url: "/excel",
                data: {
                  datos: response.data,
                },
                headers: {
                  // Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
                responseType: "blob",
              }).then((response) => {
                this.$store.state.stateLoad = false;
                // $("#boxLoading").removeClass("loading");
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", "file.xlsx");
                document.body.appendChild(link);
                link.click();

                // console.log(response);
              });
            
            });
          break;
        default:
      }
    },
    cambiarCorte(corte) {
      if (corte && corte > 0) {
        this.$store.state.stateLoad = true;
        axios
          .post(
            "/contabilidad/actualizarContable",
            {
              corte: corte
            },
            {
              headers: {
                // Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          )
          .then((response) => {
            console.log(response);
            this.$store.state.stateLoad = false;
          });
      }
    },
  },
};
</script>
<style scoped>
h1 {
  font-size: larger !important;
}
.buscar {
  width: 200px;
  border-radius: 30px !important;
  background: #2196f3;
  margin-top: 10px;
  height: 2.2rem;
  color: white;
}
</style>
