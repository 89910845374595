<template>
  <nav>
    <link
      href="https://cdn.jsdelivr.net/npm/@mdi/font@5.x/css/materialdesignicons.min.css"
      rel="stylesheet"
    />
    <link
      href="https://fonts.googleapis.com/css?family=Material+Icons"
      rel="stylesheet"
    />
    <link
      href="https://cdn.jsdelivr.net/npm/font-awesome@4.x/css/font-awesome.min.css"
      rel="stylesheet"
    />
    <link
      href="https://use.fontawesome.com/releases/v5.0.13/css/all.css"
      rel="stylesheet"
    />
    <v-app-bar class="teal">
      <v-app-bar-nav-icon
        @click.stop="drawer = !drawer"
        style="color: white"
      ></v-app-bar-nav-icon>
      <v-spacer></v-spacer>
      {{ user }}
    </v-app-bar>
    <v-navigation-drawer
      style="position: fixed"
      :expand-on-hover="expand_on_hover"
      :permanent="!temporary"
      v-model="drawer"
      :absolute="true"
      :temporary="temporary"
      dark
      app
      class="teal"
    >
      <v-list nav dense>
        <v-list-item class="mb-5">
          <v-list-item-content>
            <v-icon @click="fix" class="mb-2" large> fas fa-user-md </v-icon>
            <v-list-item-subtitle align="center"> VITALSOFT </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-list flat>
        <v-list-item v-if="categorias[1]">
          <v-list-item-content>
            <v-list-group :value="false" color="blue-grey lighten-3">
              <template v-slot:activator>
                <v-list-item-title>Dashboard</v-list-item-title>
              </template>

              <v-list-item-content>
                <v-list-item
                  v-if="credentials['Dashboard']"
                  router
                  :to="{ name: 'inicio' }"
                >
                  <v-list-item-content>
                    <v-icon class="mb-2"> fas fa-home </v-icon>
                    <v-list-item-subtitle align="center"> Inicio </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <!--<v-list-item router to="/about">
                  <v-list-item-content>
                    <v-icon class="mb-2"> fas fa-user </v-icon>
                    <v-list-item-subtitle align="center"> Usuario </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>-->
                <v-list-item
                  v-if="credentials['Agenda Personal']"
                  router
                  :to="{ name: 'calendar' }"
                >
                  <v-list-item-content>
                    <v-icon class="mb-2"> fas fa-calendar </v-icon>
                    <v-list-item-subtitle align="center">
                      Calendario
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item v-if="false" router :to="{ name: 'agregar' }">
                  <v-list-item-content>
                    <v-icon class="mb-2"> fas fa-user-plus </v-icon>
                    <v-list-item-subtitle align="center">
                      Agregar Usuario
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-content>
            </v-list-group>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="categorias[2]">
          <v-list-item-content>
            <v-list-group :value="false" color="blue-grey lighten-3">
              <template v-slot:activator>
                <v-list-item-title>Servicio Veterinario</v-list-item-title>
              </template>

              <v-list-group
                :value="false"
                no-action
                sub-group
                color="blue-grey lighten-3"
                v-if="categorias[3]"
              >
                <template v-slot:activator>
                  <v-list-item-content>
                    <v-list-item-title>Desparasitación</v-list-item-title>
                  </v-list-item-content>
                </template>

                <v-list-item
                  v-if="credentials['Registrar Desparasitacion']"
                  router
                  :to="{ name: 'registrar' }"
                  style="padding: 0"
                >
                  <v-list-item-content>
                    <v-icon class="mb-2"> fas fa-plus </v-icon>
                    <v-list-item-subtitle align="center">
                      Registrar
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-group
                  v-if="categorias[4]"
                  no-action
                  sub-group
                  color="blue-grey lighten-3"
                >
                  <template v-slot:activator>
                    <v-list-item-content>
                      <v-list-item-title>Ciclo Activo</v-list-item-title>
                    </v-list-item-content>
                  </template>

                  <v-list-item
                    v-if="credentials['Imprimir Etiquetas Desparasitacion']"
                    router
                    :to="{ name: 'pdfservicioveterinario' }"
                    style="padding: 0"
                  >
                    <v-list-item-content>
                      <v-icon class="mb-2"> fas fa-book </v-icon>
                      <v-list-item-subtitle align="center">
                        Imprimir PDF Etiquetas
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item
                    v-if="credentials['Cambiar Ciclo Activo']"
                    router
                    :to="{ name: 'nuevocicloactivo' }"
                    style="padding: 0"
                  >
                    <v-list-item-content>
                      <v-icon class="mb-2"> fas fa-plus </v-icon>
                      <v-list-item-subtitle align="center">
                        Ingresar Nuevo Ciclo Activo
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-group>

                <v-list-group
                  v-if="categorias[5]"
                  no-action
                  sub-group
                  color="blue-grey lighten-3"
                >
                  <template v-slot:activator>
                    <v-list-item-content>
                      <v-list-item-title>Informes</v-list-item-title>
                    </v-list-item-content>
                  </template>

                  <v-list-item
                    v-if="credentials['Ver Historico Desparasitacion']"
                    router
                    :to="{ name: 'historicodesparasitacion' }"
                    style="padding: 0"
                  >
                    <v-list-item-content>
                      <v-icon class="mb-2"> fas fa-book </v-icon>
                      <v-list-item-subtitle align="center">
                        Historico Desparasitación
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-group>
              </v-list-group>
            </v-list-group>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="categorias[6]">
          <v-list-item-content>
            <v-list-group :value="false" color="blue-grey lighten-3">
              <template v-slot:activator>
                <v-list-item-title>Cobradores</v-list-item-title>
              </template>

              <v-list-group
                :value="false"
                no-action
                sub-group
                color="blue-grey lighten-3"
                v-if="categorias[7]"
              >
                <template v-slot:activator>
                  <v-list-item-content>
                    <v-list-item-title>Modulo Desparasitación </v-list-item-title>
                  </v-list-item-content>
                </template>

                <v-list-item
                  v-if="credentials['Registrar Entrega Desparasitacion Cobrador']"
                  router
                  :to="{ name: 'registrarentregadesparasitacion' }"
                  style="padding: 0"
                >
                  <v-list-item-content>
                    <v-icon class="mb-2"> fas fa-hands </v-icon>
                    <v-list-item-subtitle align="center">
                      Entregar Desparasitación
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list-group>
            </v-list-group>
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-if="categorias[8]">
          <v-list-item-content>
            <v-list-group :value="false" color="blue-grey lighten-3">
              <template v-slot:activator>
                <v-list-item-title>Contabilidad</v-list-item-title>
              </template>
              <v-list-item
                v-if="credentials['Exportar Contables Facturador']"
                router
                :to="{ name: 'exportarcontables' }"
                style="padding: 0"
              >
                <v-list-item-content>
                  <v-icon class="mb-2"> fas fa-book </v-icon>
                  <v-list-item-subtitle align="center">
                    Exportar Contables
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                v-if="credentials['Exportar Contables Facturador']"
                router
                :to="{ name: 'exportarcontablesdocument' }"
                style="padding: 0"
              >
                <v-list-item-content>
                  <v-icon class="mb-2"> fas fa-book </v-icon>
                  <v-list-item-subtitle align="center">
                    Exportar Contables Document
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="categorias[9]">
          <v-list-item-content>
            <v-list-group :value="false" color="blue-grey lighten-3">
              <template v-slot:activator>
                <v-list-item-title>Historias Clinicas</v-list-item-title>
              </template>
              <v-list-item
                v-if="credentials['Descargar Historias Clinicas']"
                router
                :to="{ name: 'historiasclinicasmascotas' }"
                style="padding: 0"
              >
                <v-list-item-content>
                  <v-icon class="mb-2"> fas fa-book </v-icon>
                  <v-list-item-subtitle align="center">
                    historias clinicas mascotas
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-list style="vertical-align: baseline" class="ml-3" flat>
        <v-list-item @click="signOut">
          <v-list-item-action>
            <v-icon right> fas fa-sign-out-alt </v-icon>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </nav>
</template>

<script>
import axios from "axios";
import "material-design-icons-iconfont/dist/material-design-icons.css";
export default {
  data: () => ({
    drawer: false,
    calendarComponent: false,
    groupVetService: false,
    dewormingComponent: false,
    dewormingRegisterComponent: false,
    dewormingHistoricComponent: false,
    reportComponent: false,
    collectorsComponent: false,
    CicloActivo: false,
    moduloCicloActivo: false,
    expand_on_hover: false,

    temporary: true,
    user: null,
    credentials: null,
    facturacion: false,
    consultas: false,
    informes: false,
    cobranza: false,
    contabilidad: false,
    categorias: null,
  }),
  computed: {
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
  },
  methods: {
    signOut(event) {
      event.preventDefault();

      axios
        .post(
          "/logout",
          {},
          {
            /* headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            }, */
          }
        )
        .then((response) => {
          this.$store.commit("desactivar");
          this.$router.push({
            name: "Login",
          });
        });
    },
    fix() {
      this.temporary = !this.temporary;
    },
  },
  created() {
    this.user = this.$store.state.user.nombre1;
    this.credentials = this.$store.state.credentials;
    this.categorias = this.$store.state.categorias;
    /*  = JSON.parse(localStorage.getItem("credentials"));
    this.calendarComponent =
      this.credentials["Modulo Calendario"] || this.user[0]["full-access"] == "yes";
    this.groupVetService =
      this.credentials["Agrupacion Servicio Veterinario"] ||
      this.user[0]["full-access"] == "yes";
    this.dewormingComponent =
      this.credentials["Agrupacion Desparasitacion"] ||
      this.user[0]["full-access"] == "yes";
    this.reportComponent =
      this.credentials["Agrupacion Reportes"] || this.user[0]["full-access"] == "yes";
    this.dewormingRegisterComponent =
      this.credentials["Modulo Registrar Desparasitacion"] ||
      this.user[0]["full-access"] == "yes";
    this.dewormingHistoricComponent =
      this.credentials["Modulo Historico Desparasitacion"] ||
      this.user[0]["full-access"] == "yes";
    this.collectorsComponent =
      this.credentials["Agrupacion Cobradores"] || this.user[0]["full-access"] == "yes";
    this.collectorsDewormingComponent =
      this.credentials["Agrupacion Cobradores Desparasitacion"] ||
      this.user[0]["full-access"] == "yes";
    this.collectorsDewormingRegisterComponent =
      this.credentials["Modulo Registrar Desparasitacion Cobradores"] ||
      this.user[0]["full-access"] == "yes";
    this.PDF =
      this.credentials["Modulo Generar PDF"] || this.user[0]["full-access"] == "yes";
    this.CicloActivo =
      this.credentials["Agrupacion Ciclo Activo"] || this.user[0]["full-access"] == "yes";
    this.moduloCicloActivo =
      this.credentials["Modulo Generar Ciclo Activo"] ||
      this.user[0]["full-access"] == "yes";
    this.contabilidad =
      this.credentials["Agrupacion Contabilidad"] || this.user[0]["full-access"] == "yes"; */
  },
  beforeMount() {
    /* userCredentials.forEach(element => {
      if(element.pivot.permission_id == 1){
        this.calendarComponent = true;
      }
    }); */
  },
};
</script>
<style scoped>
.v-list-item {
  padding: 5px !important;
}
</style>
