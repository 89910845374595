<template>
  <v-app :style="{ background: $vuetify.theme.themes.light.background }">
    <v-layout row>
      <v-flex>
        <v-app :style="{ background: $vuetify.theme.themes.dark.background }" class="">
          <v-container fluid>
            <v-row class="pt-5 pb-5" style="width: 100%">
              <v-col cols="12" sm="12">
                <v-card>
                  <template>
                    <v-data-table
                      :headers="headers"
                      :items="datos"
                      :single-expand="singleExpand"
                      :expanded.sync="expanded"
                      item-key="ID_Ben"
                      show-expand
                      class="elevation-1"
                      :search="search"
                      ref="datatable"
                      :loading="loading"
                    >
                      <template v-slot:top>
                        <v-toolbar flat>
                          <v-toolbar-title>
                            <v-btn
                              @click="excel"
                              height="100%"
                              dark
                              color="green darken-1"
                            >
                              <v-icon class="mb-2" large> fas fa-file-excel </v-icon>
                            </v-btn>
                          </v-toolbar-title>
                          <v-spacer></v-spacer>
                          <!-- <v-switch
                            v-model="singleExpand"
                            label="Single expand"
                            class="mt-2"
                          ></v-switch> -->
                          <v-text-field
                            v-model="search"
                            append-icon="mdi-magnify"
                            label="Buscar"
                            single-line
                            hide-details
                          ></v-text-field>
                        </v-toolbar>
                      </template>
                      <template v-slot:expanded-item="{ headers, item }">
                        <td :colspan="headers.length" style="padding: 0">
                          <v-card style="width: 100vw">
                            <v-card-text>
                              <v-btn
                                @click="registrar(item)"
                                height="100%"
                                dark
                                color="green darken-1"
                              >
                                Registrar Entrega
                              </v-btn>
                            </v-card-text>
                          </v-card>
                        </td>
                      </template>
                      <template v-slot:item.Orden="props">
                        <v-dialog transition="dialog-bottom-transition" max-width="600">
                          <template v-slot:activator="{ on, attrs }">
                            <p
                              @click="temp = props.item.Orden"
                              color="primary"
                              v-bind="attrs"
                              v-on="on"
                            >
                              {{ props.item.Orden }}
                            </p>
                          </template>
                          <template v-slot:default="dialog">
                            <v-card>
                              <v-toolbar color="primary" dark>Modificar Orden</v-toolbar>
                              <v-card-text>
                                <v-text-field
                                  v-model="temp"
                                  label="Orden"
                                  :rules="rules"
                                  style="margin-top: 1rem"
                                ></v-text-field>
                              </v-card-text>
                              <v-card-actions class="justify-end">
                                <v-btn
                                  color="red accent-4"
                                  style="color: white"
                                  @click="dialog.value = false"
                                  >Cancelar</v-btn
                                >
                                <v-btn
                                  color="green lighten-2"
                                  style="color: white"
                                  :disabled="temp == '' || isNaN(temp)"
                                  @click="
                                    (dialog.value = false),
                                      actualizarOrden(props.item, temp),
                                      (props.item.Orden = temp)
                                  "
                                  >Guardar</v-btn
                                >
                              </v-card-actions>
                            </v-card>
                          </template>
                        </v-dialog>
                      </template>
                    </v-data-table>
                  </template>
                  <v-dialog
                    v-model="dialogDetalles.value"
                    transition="dialog-bottom-transition"
                    max-width="600"
                  >
                    <v-card>
                      <v-toolbar color="primary" dark>{{
                        dialogDetalles.title
                      }}</v-toolbar>

                      <v-card-text>
                        <v-autocomplete
                          v-model="dialogDetalles.item"
                          :items="estados"
                          dense
                          filled
                          item-text="valor"
                          item-value="clave"
                          label="Filled"
                          style="margin-top: 1rem"
                        ></v-autocomplete>
                        <v-container fluid>
                          <v-textarea
                            clearable
                            clear-icon="mdi-close-circle"
                            label="Anotaciones"
                            v-model="anotaciones"
                          ></v-textarea>
                        </v-container>
                      </v-card-text>

                      <v-card-actions class="justify-end">
                        <v-btn text @click="dialogDetalles.value = false">Cancelar</v-btn>
                        <v-btn
                          text
                          @click="
                            (dialogDetalles.value = false),
                              registrarEntrega(
                                dialogDetalles.itemCompleto,
                                dialogDetalles.item,
                                anotaciones
                              )
                          "
                          >Guardar</v-btn
                        >
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-app>
      </v-flex>
    </v-layout>
  </v-app>
</template>

<script>
import axios from 'axios';
export default {
  data: () => ({
    selected: 8,
    ciclos: [],
    ciclosTabla: [],
    ciclosSelected: -1,
    especie: [
      { clave: "Todos", valor: 0 },
      { clave: "Canino", valor: 1 },
      { clave: "Felino", valor: 2 },
    ],
    selectedEspecie: 0,
    expanded: [],
    singleExpand: false,
    headers: [
      /* {
        text: "Dessert (100g serving)",
        align: "start",
        sortable: false,
        value: "name",
      }, */
      { text: "Orden", value: "Orden" },
      { text: "Nombre Beneficiario", value: "Nombre_Beneficiario" },
      { text: "Nombre Titular", value: "Nombre_Titular" },
      { text: "Dirección Titular", value: "Dirección_Titular" },
      { text: "Municipio", value: "Municipio" },

      { text: "Telefono Titular", value: "Telefono_Titular" },
      { text: "Número Desparasitación", value: "OrdenPDF" },
      { text: "Radicación", value: "Radicacion" },
      { text: "Documento Titular", value: "DocumentoTitular" },
    ],
    anotaciones: "",
    datos: [],
    search: "",
    datosExcel: [],
    dialogDetalles: {
      value: false,
      title: "Registrar Entrega",
      item: "",
      itemCompleto: null,
      anotaciones: "",
    },
    value: null,
    items: ["foo", "bar", "fizz", "buzz"],
    temp: null,
    estados: [],
    razasClaveValor: {},
    loading: false,
    rules: [
      (value) => !!value || "Requerido.",
      (value) => !isNaN(value) || "Solo números",
      (value) => (value && value < 300) || "¿Estas Seguro que pesa mas de 300 kilos?",
    ],
    rulesDireccion: [(value) => !!value || "Requerido."],
    rulesCelular: [
      (value) => !!value || "Requerido.",
      (value) => !isNaN(value) || "Solo números",
    ],
  }),
  created() {
    this.loading = true;
    this.$store.state.stateLoad = true;
    axios
      .get("/cobradores/desparasitacion", {
        headers: {
          // Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        //   console.log(response);
        this.datos = [];

        response.data.forEach((element) => {
          this.datos.push({
            Orden: element.Orden == null? "Cambiar": element.Orden,
            Nombre_Beneficiario: element.bennm1,
            Nombre_Titular: element.titnom,
            Dirección_Titular: element.titdir,
            Municipio: element.munnom,
            Telefono_Titular: element.Telefono2,
            ID_Ben: element.ID_Ben,
            OrdenPDF: element.OrdenPDF,
            Estado_Entrega: element.Estado_Entrega,
            DocumentoTitular: element.titnit,
            Radicacion: element.grugru,
          });
        });
        this.loading = false;
        
      });
    axios
      .get("/cobradores/estados", {
        headers: {
          // Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        //   console.log(response);
        this.estados = response.data;
        this.$store.state.stateLoad = false;
      });
  },
  computed: {
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
  },
  methods: {
    esto(algo) {
      console.log(algo);
      this.$refs.datatable.dark = true;
    },
    registrar(item) {
      this.dialogDetalles.item = item.Estado_Entrega;
      this.dialogDetalles.itemCompleto = item;
      this.anotaciones = "";
      this.dialogDetalles.value = true;
    },
    registrarEntrega(itemCompleto, item, anotaciones) {
      console.log(itemCompleto, item, anotaciones);
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            console.log(position.coords);
            this.datos.forEach((element, index) => {
              if (element.ID_Ben == itemCompleto.ID_Ben) {
                element.Estado_Entrega = item;
                if (element.Estado_Entrega == 2) {
                  this.datos.splice(index, 1);
                }
                this.$store.state.stateLoad = true;
                axios
                  .post(
                    "/cobradores/actualizarEntrega",
                    {
                      estado: item,
                      usuario: `${this.$store.state.user.nombre1} ${this.$store.state.user.nombre2} ${this.$store.state.user.apellido1} ${this.$store.state.user.apellido2}`,
                      coordenadas:
                        position.coords.latitude + " , " + position.coords.longitude,
                      notas: anotaciones,
                      id: itemCompleto.ID_Ben,
                    },
                    {
                      headers: {
                        // Authorization: `Bearer ${localStorage.getItem("token")}`,
                      },
                    }
                  )
                  .then((response) => {
                    this.$store.state.stateLoad = false;
                    Swal.fire({
                      icon: "success",
                      title: "Desparasitación Actualizada! ",
                      text: "Datos Actualizados",
                    });
                    return;
                  });
              }
            });
          },
          (err) => {
            Swal.fire({
              icon: "error",
              title: "Su navegador no ha habilitado la geolocalización ",
              text: "Por Favor Habilítela",
            });
          }
        );
      } else {
        Swal.fire({
          icon: "error",
          title: "Su navegador no soporta la geolocalización ",
          text: "Por Favor Contacte a su administrador",
        });
      }
    },
    actualizarOrden(itemCompleto, valor) {
      console.log(itemCompleto, valor);
      this.$store.state.stateLoad = true;
      axios
        .post(
          "/cobradores/actualizarOrden",
          {
            id: itemCompleto.ID_Ben,
            valor: valor,
          },
          {
            headers: {
              // Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((response) => {
          console.log(response);
          this.$store.state.stateLoad = false;
        });
    },
    prueba() {
      console.log(this.anotaciones);
    },
    excel() {
      this.$store.state.stateLoad = true;
      this.datos.forEach((element) => {
        element.descripcion_Entrega =
          element.Estado_Entrega == 0
            ? "Sin Registro"
            : element.Estado_Entrega == 1
            ? "Sin Entregar"
            : "Entregado";
      });
      axios({
        method: "post",
        url: "/excel",
        data: {
          datos: this.datos,
        },
        headers: {
          // Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        responseType: "blob",
      }).then((response) => {
        this.$store.state.stateLoad = false;
        // $("#boxLoading").removeClass("loading");
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "file.xlsx");
        document.body.appendChild(link);
        link.click();
        // console.log(response);
      });
    },
  },
};
</script>
<style>
li {
  white-space: initial;
}

select {
  border: 0px !important;
  border-bottom: black solid 1px !important;

  height: 2.5em;
  padding-left: 10px;
  font-size: 1.5rem;
  width: 100%;
}

.inputs {
  width: 31.3333333%;
  margin: 1%;
  display: flex;
  flex-flow: column;
  justify-content: flex-end;
}

.inputs > div {
  height: 100%;
  display: flex;
  align-items: center;
}

table.dataTable.dtr-inline.collapsed > tbody > tr > td.dtr-control:before {
  background-color: #6c757d !important;
}

table.dataTable thead {
  background: linear-gradient(to right, #0575e6, #00f260);
  color: white;
}

@media (max-width: 600px) {
  .alinear {
    flex-flow: column !important;
  }

  .inputs {
    width: 100%;
  }
}

.row {
  justify-content: center !important;
}

h1 {
  font-size: 1.5rem !important;
  text-align: center;
}

.buscar {
  width: 200px;
  border-radius: 30px !important;
  background: #2196f3;
  margin-top: 10px;
  height: 2.2rem;
  color: white;
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.loading {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background: url("https://media.giphy.com/media/xTk9ZvMnbIiIew7IpW/source.gif") 50% 50%
    no-repeat rgb(249, 249, 249);
  opacity: 0.8;
}
tr:hover {
  background-color: rgba(0, 0, 0, 0.05) !important;
}
</style>
