import Vue from "vue";
import Vuex, { Store } from "vuex";
import createPersistedState from "vuex-persistedstate";
import SecureLS from "secure-ls";
const ls = new SecureLS({ isCompression: false });

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [
    createPersistedState({
      storage: {
        getItem: (key) => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: (key) => ls.remove(key),
      },
    }),
  ],
  state: {
    token: "",
    user: {},
    credentials: {
      "Dashboard": false,
      "Agenda Personal": false,
      "Registrar Desparasitacion": false,
      "Imprimir Etiquetas Desparasitacion": false,
      "Cambiar Ciclo Activo": false,
      "Ver Historico Desparasitacion": false,
      "Registrar Entrega Desparasitacion Cobrador": false,
      "Exportar Contables Facturador": false,
      "Descargar Historias Clinicas": false,
    },
    categorias: {
      1: false,
      2: false,
      3: false,
      4: false,
      5: false,
      6: false,
      7: false,
      8: false,
      9: false,
    },
    stateLoad: false,
  },
  mutations: {
    activar(state, value) {
      state.credentials[value] = true;
    },
    activarCategoria(state, value) {
      state.categorias[value] = true;
      switch (value) {
        case 3:
          state.categorias[2] = true;

          break;
        case 4:
          state.categorias[2] = true;
          state.categorias[3] = true;

          break;
        case 5:
          state.categorias[2] = true;
          state.categorias[3] = true;
          break;
        case 7:
          state.categorias[6] = true;
          break;

        default:
          break;
      }
    },
    token(state, value) {
      state.token = value;
    },
    user(state, value) {
      state.user = value;
    },
    desactivar: (state) => {
      let keys = Object.keys(state.credentials);
      for (let index = 0; index < keys.length; index++) {
        state.credentials[keys[index]] = false;
      }
      let key_categorias = Object.keys(state.categorias);
      for (let index = 0; index < key_categorias.length; index++) {
        state.categorias[key_categorias[index]] = false;
      }
      state.token = "";
      state.user = {};
    },
  },
  actions: {},
  modules: {},
});
