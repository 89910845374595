import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import Swal from "sweetalert2";
import SideBar from "./components/SideBar";
import Axios from "axios";
import Calendar from "./components/Calendar";
import SimpleDatatable from "./components/SimpleDatatable";
import "primevue/resources/themes/saga-blue/theme.css";
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";
import PrimeVue from "primevue/config";
import Dropdown from "primevue/dropdown";
import FullCalendar from "./components/FullCalendar";
import MultiSelect from "primevue/multiselect";
import SimpleDatatable2 from "./components/SimpleDatatable2";
import AnotherSimpleDatatable from "./components/AnotherSimpleDatatable";
import TableUsersInfo from './components/TableUsersInfo';
import Page404 from './components/404.vue'
Vue.component("full-calendar", FullCalendar);
Vue.config.productionTip = false;
Vue.component("side-bar", SideBar);
Vue.component("calendar-today", Calendar);
Vue.component("simple-datatable", SimpleDatatable);
Vue.component("Dropdown", Dropdown);
Vue.component('table-users-info',TableUsersInfo)
Vue.component("MultiSelect", MultiSelect);
Vue.component("full-calendar", FullCalendar);
Vue.component("another-simple-datatable", AnotherSimpleDatatable);
Vue.component("simple-datatable2", SimpleDatatable2);
Vue.component("page-404", Page404);
window.Swal = Swal;
window.Axios = Axios;
//TESTEO
// Axios.defaults.baseURL = "http://127.0.0.1:90/api/";
Axios.defaults.headers.common['Authorization'] = `Bearer ${store.state.token}`;

//PRODUCCION
Axios.defaults.baseURL = "https://vitalsoft.vitalmascotas.com.co/public/api/";

Axios.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error

    if (error.response.status === 401 && error.response.config.url != "login") {
      store.state.token = "";
      router.push({
        name: "Login",
      });
    }
    return Promise.reject(error);
  }
);

new Vue({
  router,
  store,
  vuetify,
  PrimeVue,
  render: function (h) {
    return h(App);
  },
}).$mount("#app");
